// App.js
import React from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import { AuthProvider, useAuth } from "./AuthContext";
import LoginPage from "./components/LoginPage";
import Dashboard from "./components/Dashboard";
import AddDevice from "./components/AddDevice";
import DemoPage from "./components/DemoPage";
import DeviceMonitor from "./components/DeviceMonitor"; // Import DeviceMonitor

const PrivateRoute = ({ children }) => {
  const { user } = useAuth();
  return user ? children : <Navigate to="/login" />;
};

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route path="/" element={<DemoPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
          <Route path="/add-device" element={<PrivateRoute><AddDevice /></PrivateRoute>} />
          <Route path="/device-monitor/:espId" element={<PrivateRoute><DeviceMonitor /></PrivateRoute>} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
