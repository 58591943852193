// components/AddDevice.js
import React, { useState } from "react";
import { firestore, auth } from "../firebase";
import { ToastContainer, toast } from "react-toastify";
import { doc, getDoc, updateDoc, setDoc, arrayUnion } from "firebase/firestore"; 
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from "react-router-dom"; // Import useNavigate

const AddDevice = () => {
  const [deviceId, setDeviceId] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate(); // Initialize navigate

  const addDevice = async () => {
    try {
      const deviceRef = doc(firestore, "Devices", deviceId);
      const deviceDoc = await getDoc(deviceRef);

      if (!deviceDoc.exists()) {
        toast.error("Device ID not found!");
        return;
      }

      const deviceData = deviceDoc.data();
      if (deviceData.password !== password) {
        toast.error("Incorrect password!");
        return;
      }

      if (deviceData.registeredWith === "No") {
        await updateDoc(deviceRef, {
          registeredWith: auth.currentUser.uid
        });

        const deviceDetails = {
          espId: deviceData.espId,
          name: deviceData.name,
          password: deviceData.password,
          phone: deviceData.phone,
          registeredWith: auth.currentUser.uid,
          type: deviceData.type
        };

        const profileRef = doc(firestore, "Profiles", auth.currentUser.uid);
        const profileDoc = await getDoc(profileRef);
        
        if (profileDoc.exists()) {
          await updateDoc(profileRef, {
            devices: arrayUnion(deviceDetails)
          });
        } else {
          await setDoc(profileRef, {
            devices: [deviceDetails]
          });
        }

        toast.success("Device added successfully!");
        navigate("/dashboard"); // Redirect to dashboard after adding device
      } else {
        toast.error("Device already registered!");
      }
    } catch (error) {
      toast.error("Error adding device!");
    }
  };

  return (
    <div className="min-h-screen bg-gray-100 flex justify-center items-center">
      <div className="bg-white p-8 rounded-lg shadow-lg max-w-md">
        <h2 className="text-2xl font-semibold mb-4">Add Device</h2>
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Device ID</label>
          <input
            type="text"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={deviceId}
            onChange={(e) => setDeviceId(e.target.value)}
          />
        </div>
        <div className="mb-6">
          <label className="block text-sm font-medium text-gray-700">Password</label>
          <input
            type="password"
            className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          onClick={addDevice}
          className="w-full bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
        >
          Add Device
        </button>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddDevice;
