// components/DemoPage.js
import React from "react";
import { useAuth } from "../AuthContext"; // Import the useAuth hook
import { useNavigate } from "react-router-dom";

const DemoPage = () => {
  const { user } = useAuth();
  const navigate = useNavigate();

  const handleButtonClick = () => {
    if (user) {
      navigate("/dashboard");
    } else {
      navigate("/login");
    }
  };

  return (
    <div className="flex h-screen justify-center items-center bg-gray-100">
      <div className="bg-white p-8 rounded-lg shadow-lg text-center">
        <h1 className="text-3xl font-bold mb-4">Welcome to Any Time Farm</h1>
        <p className="text-lg mb-6">Your smart solution for farm management.</p>
        <button
          onClick={handleButtonClick}
          className="bg-indigo-600 text-white py-2 px-4 rounded-lg hover:bg-indigo-700 transition duration-300"
        >
          {user ? "Go to Dashboard" : "Go to Login"}
        </button>
      </div>
    </div>
  );
};

export default DemoPage;
