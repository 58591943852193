// components/DeviceMonitor.js
import React, { useEffect, useState } from "react";
import { database } from "../firebase"; // Ensure you import your Realtime Database
import { ref, onValue, update } from "firebase/database"; // Import necessary methods
import { useNavigate, useParams, useLocation } from "react-router-dom"; // Import useParams and useLocation

const DeviceMonitor = () => {
  const { espId } = useParams(); // Get the ESP ID from the URL
  const [relays, setRelays] = useState({});
  const [lastSeen, setLastSeen] = useState("Updating..."); // Initialize with "Updating..."
  const navigate = useNavigate();
  const location = useLocation(); // Get the location state

  // Retrieve relay names from location state
  const relayNames = location.state?.relayNames || []; // Get relay names passed from Dashboard

  useEffect(() => {
    const fetchRelayStates = () => {
      const deviceRef = ref(database, espId); // Reference to the device by its ID

      // Fetch relay states from Realtime Database
      onValue(deviceRef, (snapshot) => {
        if (snapshot.exists()) {
          const deviceData = snapshot.val();
          setRelays(deviceData.relays || {}); // Set relays if they exist

          // Fetch the lastSeen timestamp from deviceStatus
          const deviceStatus = deviceData.deviceStatus; // Get the deviceStatus object
          const randomChildKey = Object.keys(deviceStatus || {})[0]; // Get the first random child
          if (randomChildKey) {
            const lastSeenTimestamp = deviceStatus[randomChildKey]?.lastSeen; // Access lastSeen under the random child
            if (lastSeenTimestamp) {
              setLastSeen(new Date(lastSeenTimestamp).toLocaleString()); // Format the timestamp
            } else {
              setLastSeen("Updating..."); // Display "Updating..." if not available
            }
          } else {
            setLastSeen("Updating..."); // Handle case where there is no random child
          }
        } else {
          console.log("No such device!");
          navigate("/dashboard"); // Redirect if device not found
        }
      });
    };

    fetchRelayStates();

    // Polling mechanism to update every 10 seconds
    const intervalId = setInterval(fetchRelayStates, 10000); // 10 seconds

    return () => clearInterval(intervalId); // Cleanup interval on component unmount
  }, [espId, navigate]);

  const updateRelayState = async (relayKey, currentState) => {
    const newState = currentState === "on" ? "off" : "on"; // Determine the new state
    const relayRef = ref(database, `${espId}/relays/`); // Reference to the specific relay

    // Update the specific relay state with an object
    await update(relayRef, { [relayKey]: newState }); // Ensure the argument is an object

    // Update the local state
    setRelays((prevRelays) => ({
      ...prevRelays,
      [relayKey]: newState // Update the local state to reflect the change
    }));
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">Device Monitor - {espId}</h1>
      
      {/* Display Last Seen Timestamp */}
      <div className="bg-yellow-200 p-4 rounded-lg shadow-md mb-4">
        <h2 className="text-lg font-semibold">Last Seen:</h2>
        <p>{lastSeen}</p>
      </div>

      <div className="grid grid-cols-1 gap-4">
        {Object.keys(relays).length > 0 ? (
          Object.entries(relays).map(([key, state], index) => (
            <div key={key} className="flex justify-between items-center bg-white p-4 rounded-lg shadow-md">
              <span className="font-semibold">{relayNames[index] || `Relay ${index + 1}`}</span> {/* Display relay names */}
              <button
                onClick={() => updateRelayState(key, state)}
                className={`py-2 px-4 rounded-lg ${state === "on" ? "bg-green-500" : "bg-red-500"} text-white`}
              >
                {state === "on" ? "Turn Off" : "Turn On"}
              </button>
            </div>
          ))
        ) : (
          <p className="text-gray-600">No relays found for this device.</p>
        )}
      </div>
    </div>
  );
};

export default DeviceMonitor;
