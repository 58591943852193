// components/Dashboard.js
import React, { useEffect, useState } from "react";
import { auth, firestore } from "../firebase";
import { useNavigate } from "react-router-dom";
import { doc, getDoc } from "firebase/firestore";

const Dashboard = () => {
  const [devices, setDevices] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchDevices = async () => {
      if (!auth.currentUser) {
        navigate("/login"); // Redirect to login if not authenticated
        return;
      }

      const profileRef = doc(firestore, "Profiles", auth.currentUser.uid);
      const profileDoc = await getDoc(profileRef);

      if (profileDoc.exists()) {
        setDevices(profileDoc.data().devices);
      } else {
        console.log("Profile does not exist for user:", auth.currentUser.uid);
      }
    };

    fetchDevices();
  }, [navigate]);

  const logout = () => {
    auth.signOut().then(() => {
      navigate("/login");
    });
  };

  const handleMonitorClick = async (device) => {
    const relayNames = device.relayNames || []; // Get relay names from device
    navigate(`/device-monitor/${device.espId}`, { state: { relayNames } }); // Pass relay names to DeviceMonitor
  };

  return (
    <div className="min-h-screen bg-gray-100">
      <div className="container mx-auto p-6">
        <div className="flex justify-between items-center mb-6">
          <h1 className="text-3xl font-bold">ATF Dashboard</h1>
          <button
            onClick={logout}
            className="bg-red-600 text-white py-2 px-4 rounded-lg hover:bg-red-700 transition duration-300"
          >
            Logout
          </button>
        </div>

        <h2 className="text-2xl font-semibold mb-4">Added Devices</h2>
        {devices.length > 0 ? (
          <ul className="space-y-4">
            {devices.map((device, index) => (
              <li key={index} className="bg-white p-4 rounded-lg shadow-md">
                <p><strong>ESP ID:</strong> {device.espId}</p>
                <p><strong>Name:</strong> {device.name}</p>
                <button
                  onClick={() => handleMonitorClick(device)} // Pass the device to monitor click handler
                  className="bg-blue-600 text-white py-2 px-4 rounded-lg hover:bg-blue-700 transition duration-300 mt-2"
                >
                  Monitor
                </button>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-600">No devices added yet.</p>
        )}

        <div className="mt-6">
          <button
            onClick={() => navigate("/add-device")}
            className="bg-green-600 text-white py-2 px-4 rounded-lg hover:bg-green-700 transition duration-300"
          >
            Add Device
          </button>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
