import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import { getDatabase } from 'firebase/database'; // Import for Realtime Database

const firebaseConfig = {
    apiKey: "AIzaSyBHv766lOvZeITYSREglh1P4nCH8O7RSQY",
    authDomain: "anytimeharvest.firebaseapp.com",
    databaseURL: "https://anytimeharvest-default-rtdb.firebaseio.com",
    projectId: "anytimeharvest",
    storageBucket: "anytimeharvest.appspot.com",
    messagingSenderId: "685141005104",
    appId: "1:685141005104:web:a980279b0a8d09e5bbaef5",
    measurementId: "G-1DTYQD0XH0"
  };
  

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize services
const auth = getAuth(app);
const firestore = getFirestore(app);
const provider = new GoogleAuthProvider();
const database = getDatabase(app); // Initialize Realtime Database

export { auth,database, firestore, provider };
